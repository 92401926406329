<template>
  <div class="wrapper">
    <CModal
      :show.sync="isModalOpen"
      :closeOnBackdrop="false"
      :centered="true"
      title=""
      size="lg"
      color="secondary"
    >
      <CRow class="mb-3">
          <CCol sm="12">
            {{adminId}} 의 비밀번호를 변경합니다.
          </CCol>
      </CRow>
      <CRow v-if="$store.state.loginStore.adminType!=0 || adminType==0">
          <CCol sm="12">
            <CInput
              label="기존 비밀번호 입력"
              type="password"
              autocomplete="curent-password"
              :value.sync="$v.form.currentPassword.$model"
              :isValid="!$v.form.currentPassword.$error"
              invalidFeedback="필수 입력입니다."
              horizontal
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <CInput
                label="새 비밀번호 입력"
                type="password"
                :value.sync="$v.form.password.$model"
                :isValid="!$v.form.password.$error"
                autocomplete="new-password"
                invalidFeedback="비밀번호는 영문 대/소문자, 숫자, 특수문자를 3가지 이상 조합하여 9~20자리 이상 설정해야합니다."
                horizontal
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <CInput
                label="새 비밀번호 확인"
                type="password"
                :value.sync="$v.form.confirmPassword.$model"
                :isValid="!$v.form.confirmPassword.$error"
                autocomplete="new-password"
                invalidFeedback="비밀번호가 일치하지 않습니다."
                horizontal
            />
          </CCol>
        </CRow>
      <template #header>
        <h6 class="modal-title">비밀번호 변경</h6>
        <CButtonClose @click="$emit('close-modal')" class="text-white"/>
      </template>
      <template #footer>
        <CButton color="danger" @click="$emit('close-modal')">취소</CButton>
        <CButton color="success" @click="modifyPswd()">저장</CButton>
      </template>
    </CModal>
  </div>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required, sameAs, helpers } from "vuelidate/lib/validators"
import RSAKey from '@/js/rsa/rsa.js'
export default {
  name: 'PasswordChangeModal',
  mixins: [validationMixin],
  data () {
    return {
        isModalOpen:true,
        afterClick:false,
        form:{
          currentPassword:'',
          password:'',
          confirmPassword:'',
        },
        pubRsaKey:[]
    }
  },
  props: {
      adminId: String,
      adminKey: String,
      adminType: String,
      accountType: String,

  },
  validations: {
      form: {
          currentPassword: {
              required,
          },
          password: {
              required,
              strongPass: helpers.regex('strongPass', /^.*(?=^.{9,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!,@,#,$,%,^,&,*]).*$/)
          },
          confirmPassword: {
              required,
              sameAsPassword: sameAs("password")
          },
      }
  },
  mounted() {
      this.$v.form.$touch()
      this.getRsaKey()
  },
  methods: {
      async modifyPswd(){  
        this.afterClick = true   
        if(this.validate()){  
          const rsa = new RSAKey() 
          rsa.setPublic(this.pubRsaKey.publicKeyModulus, this.pubRsaKey.publicKeyExponent)
      
          let payload ={
              adminKey : this.adminKey,
              adminId: this.adminId,
              currentPswd : this.form.currentPassword != '' ? rsa.encrypt(this.form.currentPassword):'',
              pswd : rsa.encrypt(this.form.password),
          }

          if(this.$store.state.loginStore.adminType==0 && this.adminType!=0)
            payload.currentPswdYn = false
          else 
            payload.currentPswdYn = true
          
          this.loading=true 
          try {
              let dispatchUrl = this.accountType == 'myaccount' ? 'adminInfo/modifyMyAccountPswd' : 'adminInfo/modifyAdminPswd' 
               await this.$store.dispatch(dispatchUrl, payload)
               alert("저장하였습니다.")    
               this.$emit('close-modal')
          } catch(error){
              this.$response(error)
              this.loading=false
              this.getRsaKey() //실패시 재호출
          }
        }
        this.afterClick = false

        return true
      },
      validate(){
          if ((this.$store.state.loginStore.adminType!=0 || this.adminType==0) &&!this.$v.form.currentPassword.required ) {
              alert("기존 비밀번호를 입력해주세요.")
              return false
          }
          else if (!this.$v.form.password.required) {
              alert("새 비밀번호를 입력해주세요.")
              return false
          }
          else if (!this.$v.form.password.strongPass) {
              alert("새 비밀번호를 규칙에 맞게 입력해주세요.")
              return false
          }
          else if (!this.$v.form.confirmPassword.sameAsPassword) {
              alert("새 비밀번호 확인을 정확하게 입력해주세요.")
              return false
          }
          return true
      },
      async getRsaKey(){
        try{
          await this.$store.dispatch("loginStore/getRsaPubKey")
          this.pubRsaKey = this.$store.state.loginStore.pubKey
        }catch(error){
          console.log(error)
        }
      }
  }
}
</script>
