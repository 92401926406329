<template>
  <div>
    <CRow>
        <PasswordChangeModal v-if="isOpenPasswordModal" :adminId="adminInfo.adminId" :adminKey="adminInfo.adminKey" :adminType="adminInfo.adminType" accountType="myaccount" @close-modal="isOpenPasswordModal = false"/>
    <CCol lg="12" style="margin-bottom:10px">
        <CButton v-if="!editable" type="submit" size="sm" class="float-right btn btn-primary btn-lft-margin" @click="isOpenPasswordModal = true"><CIcon name="cil-pencil"/> 비밀번호 변경</CButton>
        <CButton v-if="!editable" type="submit" size="sm" color="primary" class="float-right" @click="changeToModifyMode"><CIcon name="cil-pencil"/> 수정</CButton>
        
        
        <form @submit.prevent="formSubmit" method="post">
        <CButton v-if="editable" type="submit" size="sm" color="primary" class="float-right btn-lft-margin" :disabled="afterClick">
            <CIcon name="cil-pencil"/> 저장
        </CButton>
            </form>
        <CButton v-if="editable" type="submit" size="sm" class="float-right" color="danger" @click="cancelAccountInfo" ><CIcon name="cil-x-circle"/> 취소</CButton> 
        </CCol>
    </CRow>
    <CRow>
        <CCol lg="5">
            <CCard>
                <CCardHeader>
                    <strong> 계정 정보 </strong>
                </CCardHeader>
                <CCardBody>
                    <CForm class="mr-2">
                        <CRow form class="form-group">
                            <CCol sm="3">
                            <Strong>아이디</Strong>
                            </CCol>
                            <CCol class="ml-1">
                            {{adminInfo.adminId}}
                            </CCol>
                        </CRow>
                        <CRow form class="form-group">
                            <CCol sm="3">
                            <Strong>관리자 구분</Strong>
                            </CCol>
                            <CCol class="ml-1">
                            {{adminType}}
                            </CCol>
                        </CRow>
                        <div v-if="!editable">
                            <CInput
                                label="이름(*)"
                                type="text"
                                :value.sync="$v.adminInfo.adminNm.$model"
                                :disabled="!editable"   
                                horizontal
                            />
                            <CInput
                                label="전화번호"
                                type="tel"
                                :lazy="false"
                                :value.sync="$v.adminInfo.phoneNumber.$model"
                                :disabled="!editable"   
                                horizontal
                            />
                            <CInput
                                label="Email"
                                type="email"
                                :lazy="false"
                                :value.sync="$v.adminInfo.email.$model"
                                :disabled="!editable"   
                                horizontal
                            />
                            <CRow form class="form-group mb-1" v-for="(v, index) in $v.adminInfo.ipInfoList.$each.$iter" :key="index">
                                <CCol sm="3" v-text="index == 0? 'IP' : ''"></CCol> 
                                <CInput
                                    type="text"
                                    class="ml-0"
                                    :lazy="false"
                                    :value.sync="v.startIp.$model"
                                    :disabled="!editable"   
                                    horizontal
                                />                             
                                <div class="c-avatar" @click="addIpInput" v-if="editable&&index==adminInfo.ipInfoList.length-1" >
                                    <img
                                        src="@/assets/img/plus.png"
                                        class="c-avatar-img "
                                    />
                                </div>
                                <div class="c-avatar" @click="removeIpInput" v-if="editable&&index!=0&&index==adminInfo.ipInfoList.length-1">
                                    <img
                                        src="@/assets/img/minus.png"
                                        class="c-avatar-img "
                                    />
                                </div> 
                            </CRow>  
                            <CTextarea
                                label="Note"
                                horizontal
                                rows="9"
                                :value.sync="$v.adminInfo.note.$model"
                                :disabled="!editable"   
                            />
                        </div>
                        <div v-if="editable">
                            <CInput
                                label="이름(*)"
                                type="text"
                                :value.sync="$v.adminInfo.adminNm.$model"
                                :isValid="!$v.adminInfo.adminNm.$error"
                                invalidFeedback="필수 입력입니다."
                                :disabled="!editable"   
                                horizontal
                            />
                            <CInput
                                label="전화번호"
                                type="tel"
                                :lazy="false"
                                placeholder="ex) 01012345678"
                                :value.sync="$v.adminInfo.phoneNumber.$model"
                                :isValid="!$v.adminInfo.phoneNumber.$error"
                                invalidFeedback="유효한 전화번호를 입력해주세요."
                                :disabled="!editable"   
                                horizontal
                            />
                            <CInput
                                label="Email"
                                type="email"
                                :lazy="false"
                                :value.sync="$v.adminInfo.email.$model"
                                :isValid="!$v.adminInfo.email.$error"
                                placeholder="ex) raon@raonsecure.com"
                                invalidFeedback="유효한 이메일을 입력해주세요."
                                :disabled="!editable"   
                                horizontal
                            />
                            <CRow form class="form-group mb-1" v-for="(v, index) in $v.adminInfo.ipInfoList.$each.$iter" :key="index">
                                <CCol sm="3" v-text="index == 0? 'IP' : ''"></CCol> 
                                <CInput
                                    type="text"
                                    class="ml-0"
                                    :lazy="false"
                                    :value.sync="v.startIp.$model"
                                    :isValid="!v.startIp.$error"
                                    invalidFeedback="유효한 IP를 입력해주세요."
                                    placeholder="ex) 192.168.0.1"
                                    :disabled="!editable"   
                                    horizontal
                                />                             
                                <!-- IP 1개로 제한.  하기 주석 풀면 여러 개의 IP 사용가능-->     
                                <!-- <div class="c-avatar" @click="addIpInput" v-if="editable&&index==adminInfo.ipInfoList.length-1" >
                                    <img
                                        src="@/assets/img/plus.png"
                                        class="c-avatar-img "
                                    />
                                </div>
                                <div class="c-avatar" @click="removeIpInput" v-if="editable&&index!=0&&index==adminInfo.ipInfoList.length-1">
                                    <img
                                        src="@/assets/img/minus.png"
                                        class="c-avatar-img "
                                    />
                                </div>  -->
                            </CRow>  
                            <CTextarea
                                label="Note"
                                placeholder=""
                                horizontal
                                rows="9"
                                :value.sync="$v.adminInfo.note.$model"
                                :disabled="!editable"   
                                :isValid="!$v.adminInfo.note.$error"
                            />
                        </div>
                    </CForm>
                </CCardBody>
            </CCard>
        </CCol>
        <CCol lg="7">
            <PermissionGroupModal v-if="isOpenPermissionGroupModal" @close-modal="isOpenPermissionGroupModal=false" @add-item-event="addPermissionItem"/>
            <CCard>
                <CCardHeader>
                    <strong> 권한 그룹 </strong>
                    <CButton  
                        v-if="adminInfo.adminType=='1' && $hasWritePermission('302')"
                        type="submit"
                        size="sm"
                        variant="ghost"
                        class="float-right btn btn-primary"
                        @click="isOpenPermissionGroupModal=true"
                        :disabled="!editable"
                    >                        
                        <CIcon name="cil-plus"/>
                    </CButton>
                </CCardHeader>
                <CCardBody>
                    {{adminInfo.groupNm}}
                </CCardBody>
            </CCard>
            <CCard>
                <CCardHeader>
                    <strong> 앱 제어 권한 </strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol lg="12" style="margin-bottom:3px">
                            <span v-if="adminInfo.allAppPermissionYn=='1'">모든 앱 허용</span>
                            <span v-if="adminInfo.allAppPermissionYn=='0'&&(!adminInfo.appPermissionInfoList||adminInfo.appPermissionInfoList.length ==0)">권한 없음</span>
                        </CCol>
                    </CRow>
                    <CDataTable
                        v-if="adminInfo.allAppPermissionYn=='0' && adminInfo.appPermissionInfoList.length>0"
                        :hover="hover"
                        :striped="striped"
                        :border="border"
                        :small="small"
                        :fixed="fixed"
                        :items="adminInfo.appPermissionInfoList"
                        :fields="appFields"
                        :items-per-page="small ? 10 : 5"
                        pagination
                    >
                    <template #no="{index}">
                        <td class="py-2" >
                            {{adminInfo.appPermissionInfoList.length-index}}
                        </td>
                    </template>
                    </CDataTable>     
                </CCardBody>
            </CCard>
            <CCard>
                <CCardHeader>
                    <strong> 메뉴 접근 권한 </strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>                        
                        <CCol lg="12" style="margin-bottom:3px">
                            <span v-if="adminInfo.allMenuPermissionYn=='1'">모든 메뉴 허용</span>
                        </CCol>
                    </CRow>
                    <CRow v-if="adminInfo.allMenuPermissionYn=='0'">
                        <CCol sm="4" v-for="(item, index) in adminInfo.menuPermissionInfoList" :key="index">
                            <CRow form class="form-group" >
                                <CCol tag="label" sm="6" class="col-form-label font-weight-bold">
                                    {{item.menuNm}}
                                </CCol>
                            </CRow>
                            <CRow form class="form-group" v-for="(subItem, subIndex) in item.childrenItems" :key="subIndex">
                                <CCol sm="5" v-if="subItem.menuKey!=102">
                                    {{subItem.menuNm}}
                                </CCol>
                                <CInputCheckbox
                                    :id="subItem.menuKey"
                                    label="Read"
                                    :inline="true"
                                    :checked="subItem.readYn==1"
                                    value="readYn"
                                    @change="checkedMenu($event)"
                                    v-if="subItem.menuKey!=102"
                                    disabled=true
                                    :custom="true"
                                    />
                                <CInputCheckbox
                                    :id="subItem.menuKey"
                                    label="Write"
                                    :checked="subItem.writeYn==1"
                                    value="writeYn"
                                    @change="checkedMenu($event)"
                                    v-if="item.menuKey!=101"
                                    disabled=true
                                    :custom="true"
                                    />
                                
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
  </div>
</template>
<script>
import PasswordChangeModal from './PasswordChangeModal'
import PermissionGroupModal from '../permissionGroup/PermissionGroupModal'
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers, numeric, maxLength } from "vuelidate/lib/validators"

export default {
    name: 'AccountInfo',
    components:{
        PasswordChangeModal,
        PermissionGroupModal
    },
    mixins: [validationMixin],
    data () {
        return {
            loading: false, // 로딩바 활성화 여부
            editable:false, // 편집가능상태
            afterClick: false, // 버튼 클릭 직후 여부(버튼 중복 클릭되지 않도록)
            isOpenPasswordModal: false,
            // 그리드 컬럼 설정
            appFields:[
                { key: 'no', label:'No', _style: { width: '1%'} },
                { key: 'appNm', label:'앱 이름', _style: { width: '20%'} },
                { key: 'packageNm', label:'패키지명', _style: { width: '20%'} },
                { key: 'appVersion', label:'버전',  _style: { width: '20%'} },
                { key: 'osType', label: 'OS', _style: { width: '20%'}, iconYn: true }
            ],
            permissionGroupInfo:{},
            isOpenPermissionGroupModal: false,
            adminInfo:{},
            mode:'0',
        }
    }, 
    props: {
        caption: {
            type: String,
            default: '계정관리'
        },
        hover: Boolean,
        striped: Boolean,
        border: Boolean,
        small: Boolean,
        fixed: Boolean,
        dark: Boolean,
    },
    computed:{        
      adminKey () {
        return this.$store.state.loginStore.adminKey 
      },   
      adminType (){
          return this.$renderer('adminType', 0, this.adminInfo.adminType)
      }
    },
    mounted(){
        this.editable = false
    },
    created () {
        this.selectAdminInfo()
    },
    validations: {
        adminInfo: {
            adminNm: {
                required,
            },
            email: {
                email
            },
            phoneNumber: {
                numeric,                
            },
            ipInfoList: {     
                $each: {
                    startIp:{
                       ipRegex: helpers.regex('ipRegex',/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)
                    }
                }                
            },
            note:{
                minLength: minLength(0),
                maxLength : maxLength(200)
            },
        }
    },
    methods: {
        //수정 버튼 클릭시 동작하는 함수
        async formSubmit(){

            this.afterClick = true      
            
            if(this.validate()){        
                let payload ={
                    adminKey : this.adminInfo.adminKey,
                    adminId: this.adminInfo.adminId,
                    adminNm : this.adminInfo.adminNm,
                    phoneNumber : this.adminInfo.phoneNumber,
                    email : this.adminInfo.email,
                    ipInfoList : this.adminInfo.ipInfoList,
                    note : this.adminInfo.note,
                    groupKey : this.adminInfo.groupKey,
                    groupNm: this.adminInfo.groupNm
                }
                this.loading=true 
                try {
                    await this.$store.dispatch('adminInfo/modifyMyAccount', payload)
                    alert("저장하였습니다.")
                    if(this.mode=='1') this.$router.go(-1)
                    else{
                        this.editable = false
                        this.loading=false 
                        this.reset()
                    }
                } catch(error){
                    this.$response(error)
                    this.loading=false
                }
            }
            this.afterClick = false

            return true
        },
        async selectAdminInfo() {            
            this.loading=true 
            try {
                let response = await this.$store.dispatch('adminInfo/getMyAccount', this.adminKey)
                if(response.status == 200){
                    this.adminInfo=response.data
                    if(this.adminInfo.ipInfoList.length==0) this.addIpInput()
                }
                this.loading=false
            } catch(error){
                this.$response(error)
                this.loading=false
            }

            return true
        },
        changeToModifyMode() {
            this.editable = true
        },
        cancelAccountInfo(){
            this.editable = false
            this.reset()
            this.selectAdminInfo()
        },
        goToList(){            
            this.$router.push({name: 'AccountList'})
        },
        addIpInput(){
            this.adminInfo.ipInfoList.push({startIp: ''})
        },
        removeIpInput(){
            this.adminInfo.ipInfoList.pop({startIp: ''})
        },
        addPermissionItem(data){
            if(data){
                let permissionGroupInfo = data[0]
                
                // 권한 상세조회
                if(!permissionGroupInfo.allAppChecked || !permissionGroupInfo.allMenuChecked){
                    this.searchInfo(permissionGroupInfo.groupKey)
                }
            } 
            this.isOpenPermissionGroupModal = false
        },  
        async searchInfo(groupKey){
            try{
                let response = await this.$store.dispatch('permissionGroup/getPermissionGroupInfo', groupKey)
                    if(response.status == 200){
                        this.adminInfo.groupKey = response.data['groupKey'];
                        this.adminInfo.groupNm = response.data['groupNm'];
                        this.adminInfo.allAppPermissionYn = response.data['allAppPermissionYn'];
                        this.adminInfo.allMenuPermissionYn = response.data['allMenuPermissionYn'];
                        this.adminInfo.appPermissionInfoList = response.data['appPermissionInfoList'];
                        this.adminInfo.menuPermissionInfoList = response.data['menuPermissionInfoList'];
                    }
            }catch(error){
                console.log(error)
            }

        },
        checkIfValid (fieldName) {
            const field = this.$v.form[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        reset () {
            //this.form = this.getEmptyForm()
            this.$v.$reset()
        },
        validate(){
            if (!this.$v.adminInfo.adminNm.required) {
                alert("이름을 입력해주세요.")
                return false
            }
            else if (!this.$v.adminInfo.phoneNumber.numeric) {
                alert("전화번호 형식이 유효하지 않습니다.")
                return false
            }
            else if (!this.$v.adminInfo.email.email) {
                alert("이메일 형식이 유효하지 않습니다.")
                return false
            }
            else if (this.$v.adminInfo.ipInfoList.$error) {
                alert("IP 형식이 유효하지 않습니다.")
                return false
            }
            return true
        }
    }
}
</script>
